import {
  createRouter,
  // createWebHistory,
  createWebHashHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/ai",
    name: "Ai",
    component: () => import("@/views/ai/index.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/about/index.vue"),
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: () => import("@/views/jobs/index.vue"),
  },
  {
    path: "/his",
    name: "His",
    component: () => import("@/views/his/index.vue"),
  },
  {
    path: "/community",
    name: "Community",
    component: () => import("@/views/community/index.vue"),
  },
  {
    path: "/c9",
    name: "C9",
    component: () => import("@/views/c9/index.vue"),
  },
  {
    path: "/chatbot",
    name: "ChatBot",
    component: () => import("@/views/chatbot/index.vue"),
  },
  {
    path: "/chatbotDetail",
    name: "ChatBotDetail",
    component: () => import("@/views/chatbotDetail/index.vue"),
  },
  {
    path: "/night",
    name: "Night",
    component: () => import("@/views/night/index.vue"),
  },

  {
    path: "/en",
    // name: 'Home',
    component: () => import("@/views/en/index.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/en/home/index.vue"),
      },
      {
        path: "/en/ai",
        name: "EnAi",
        component: () => import("@/views/en/ai/index.vue"),
      },
      {
        path: "/en/about",
        component: () => import("@/views/en/about/index.vue"),
      },
      {
        path: "/en/jobs",
        component: () => import("@/views/en/jobs/index.vue"),
      },
      {
        path: "/en/his",
        component: () => import("@/views/en/his/index.vue"),
      },
      {
        path: "/en/community",
        component: () => import("@/views/en/community/index.vue"),
      },
      {
        path: "/en/c9",
        component: () => import("@/views/en/c9/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  // base: process.env.BASE_URL,
  routes,
});

/* router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) {
		next({path: '/login', replace: true});
	} else {
		next()
	}
}) */

export default router;
