<template>
  <div class="head-container">
    <div id="header">
      <div class="wrap">
        <div class="container">
          <div class="menuBtn" @click="toggleMenu"><i></i></div>
          <div class="logo">
            <img class="img" :src="logoUrl" />
          </div>
          <div class="navigator" :class="{ open: openMenu }">
            <ul>
              <li class="first">Oneli</li>
              <template v-if="lang == 'ch'">
                <!-- <li
                  :class="{ active: menuIndex == -1 }"
                  @click="routerTo('/ai')"
                >
                  <span class="v1">智能助手</span>
                </li> -->
                <li :class="{ active: menuIndex == 0 }" @click="routerTo('/')">
                  <span class="v1">首页热门</span>
                </li>
                <li
                  :class="{ active: menuIndex == 1 }"
                  @click="routerTo('/about')"
                >
                  <span class="v1">关于我们</span>
                </li>
                <li
                  :class="{ active: menuIndex == 2 }"
                  @click="routerTo('/jobs')"
                >
                  <span class="v1">求贤招聘</span>
                </li>
                <li
                  :class="{ active: menuIndex == 3 }"
                  @click="routerTo('/his')"
                >
                  <span class="v1">赞助名单</span>
                </li>
                <a-dropdown>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <router-link :to="{ path: '/c9' }"
                          >高校联盟</router-link
                        >
                      </a-menu-item>
                      <a-menu-item>
                        <router-link :to="{ path: '/community' }"
                          >产业联盟</router-link
                        >
                      </a-menu-item>
                      <a-menu-item>
                        <router-link :to="{ path: '/night' }"
                          >天问九歌系列人物专访</router-link
                        >
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <li class="menu-item" :class="{ active: menuIndex == 4 }">
                    <span class="v1" style="margin-right: 5px">社区生态</span>
                    <DownOutlined />
                  </li>
                </a-dropdown>
              </template>
              <template v-else>
                <li
                  :class="{ active: menuIndex == -1 }"
                  @click="routerTo('/en/ai')"
                >
                  <span class="v1">intelligence assistant</span>
                </li>
                <li
                  :class="{ active: menuIndex == 0 }"
                  @click="routerTo('/en')"
                >
                  <span class="v1">Title Trending</span>
                </li>
                <li
                  :class="{ active: menuIndex == 1 }"
                  @click="routerTo('/en/about')"
                >
                  <span class="v1">About Us</span>
                </li>
                <li
                  :class="{ active: menuIndex == 2 }"
                  @click="routerTo('/en/jobs')"
                >
                  <span class="v1">Recruitment</span>
                </li>
                <li
                  :class="{ active: menuIndex == 3 }"
                  @click="routerTo('/en/his')"
                >
                  <span class="v1">Sponsor List</span>
                </li>
                <a-dropdown>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <router-link :to="{ path: '/en/c9' }"
                          >C9 University Alliance</router-link
                        >
                      </a-menu-item>
                      <a-menu-item>
                        <router-link :to="{ path: '/en/community' }"
                          >Industry Alliance</router-link
                        >
                      </a-menu-item>
                      <a-menu-item>
                        <router-link :to="{ path: '/night' }"
                          >天问九歌系列人物专访</router-link
                        >
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <li class="menu-item" :class="{ active: menuIndex == 4 }">
                    <span class="v1" style="margin-right: 5px"
                      >Community Ecology</span
                    >
                    <DownOutlined />
                  </li>
                </a-dropdown>
              </template>
            </ul>
            <div class="overlay" @click="toggleMenu"></div>
          </div>
          <div class="lang">
            <span v-if="lang == 'ch'" @click="setLang('en')">English</span>
            <span v-else @click="setLang('ch')">中文</span>
          </div>
        </div>
      </div>
    </div>
    <div class="header_space"></div>
  </div>
</template>

<script>
import { DownOutlined } from "@ant-design/icons-vue";
export default {
  name: "page_header",
  components: {
    DownOutlined,
  },
  props: {
    menuIndex: {
      type: Number,
      default: 0,
    },
    openMenu: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      logoUrl: require("@/assets/images/logo.png"),
    };
  },
  methods: {
    toggleMenu() {
      this.$emit("toggle");
    },
    routerTo(path) {
      this.$emit("menuClick", path);
    },
    setLang(lang) {
      console.log(lang);
      this.$emit("setLang", lang);

      /* let oldpath = this.$route.fullPath;
      if (oldpath.indexOf('/en') !== -1) {
        this.routerTo(oldpath.replace('/en', ''));
      } else {
        this.routerTo('/en' + oldpath);
      } */
    },
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/assets/style/head.styl";
.menu-item
  display: flex;
  align-items: center;
</style>
