import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import "@/assets/style/common.styl";

import store from "./store";

const app = createApp(App);
app.use(store).use(router).use(Antd).mount("#app");
