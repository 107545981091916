<template>
  <div class="foot-container">
    <template v-if="lang == 'en'">
      <div class="index-row index-partner">
        <div class="wrap">
          <h2 class="gt1">Community Organizing Committee and Partners</h2>
          <div class="g_list4">
            <ul>
              <li v-for="(item, index) in partList_en" :key="index">
                <h3 class="title">{{ item.label }}</h3>
                <div class="list">
                  <dl>
                    <dd v-for="(sitem, sindex) in item.children" :key="sindex">
                      <template v-if="sitem.link">
                        <a :href="sitem.link" target="_blank">{{
                          sitem.label
                        }}</a>
                      </template>
                      <template v-else>{{ sitem.label }}</template>
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="footer" class="index-row index-contact">
        <div class="wrap">
          <h2 class="gt1">Contact Us</h2>
          <div class="g_list5">
            <ul>
              <li>
                <div class="code">
                  <img src="@/assets/images/talk/code.png" />
                </div>
                <p>WeChat Channels</p>
              </li>
              <li>
                <div class="code">
                  <img src="@/assets/images/talk/oneli.png" />
                </div>
                <p>Community Operation WeChat ( oneli_111)</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="index-row index-partner">
        <div class="wrap">
          <h2 class="gt1">社区组委会与合作伙伴</h2>
          <div class="g_list4">
            <ul>
              <li v-for="(item, index) in partList" :key="index">
                <h3 class="title">{{ item.label }}</h3>
                <div class="list">
                  <dl>
                    <dd v-for="(sitem, sindex) in item.children" :key="sindex">
                      <template v-if="sitem.link">
                        <a :href="sitem.link" target="_blank">{{
                          sitem.label
                        }}</a>
                      </template>
                      <template v-else>{{ sitem.label }}</template>
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="footer" class="index-row index-contact">
        <div class="wrap">
          <h2 class="gt1">联系我们</h2>
          <div class="g_list5">
            <ul>
              <li>
                <div class="code">
                  <img src="@/assets/images/talk/code.png" />
                </div>
                <p>微信视频号</p>
              </li>
              <li>
                <div class="code">
                  <img src="@/assets/images/talk/oneli.png" />
                </div>
                <p>社区运营wechat(oneli_111)</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>

    <h2 class="gt1">
      <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021033296号</a>
    </h2>
  </div>
</template>

<script>
export default {
  name: "page_header",
  props: {
    lang: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      partList: [
        {
          label: "主办单位",
          children: [
            {
              label: "Oneli.AI开源基金会",
              link: "#",
            },
            {
              label: "杭州一往科技有限公司",
              link: "https://yiwang.info/home",
            },
          ],
        },
        {
          label: "协办单位",
          children: [
            {
              label: "杭州瑞视界智能科技有限公司",
              link: "https://www.rsjyun.com/",
            },
            {
              label: "杭州维卡科技有限公司",
              link: "http://www.vcard.com.cn/",
            },
          ],
        },
        {
          label: "合作院校",
          children: [
            {
              label: "浙江大学",
              link: "https://www.zju.edu.cn/",
            },
            {
              label: "中国科技大学",
              link: "https://www.ustc.edu.cn/",
            },
          ],
        },
        {
          label: "社区伙伴",
          children: [
            {
              label: "Linux Foundation",
              link: "https://www.linuxfoundation.org/",
            },
            {
              label: "LF Edge AI",
              link: "https://lfedge.org/projects/infiniedge-ai/",
            },
          ],
        },
        {
          label: "战略合作",
          children: [
            {
              label: "浙江宏昊律师事务所",
              link: "https://www.zjhhlawyer.com/",
            },
            {
              label: "绍兴大统会计师事务所",
              link: "http://www.dtcpa.cn/",
            },
          ],
        },
        {
          label: "特邀媒体",
          children: [
            {
              label: "杭州都市快报",
              link: "",
            },
            {
              label: "潮新闻APP",
              link: "",
            },
          ],
        },
      ],
      partList_en: [
        {
          label: "Organizer",
          children: [
            {
              label: "Oneli AI Open Source Foundation",
              link: "#",
            },
            {
              label: "Hangzhou One To Technology Co., LTD",
              link: "https://yiwang.info/home",
            },
          ],
        },
        {
          label: "Co-organizer",
          children: [
            {
              label: "Hangzhou RUI Vision Intelligent Technology Co., LTD",
              link: "https://www.rsjyun.com/",
            },
            {
              label: "Hangzhou Veka Technology Co., LTD",
              link: "http://www.vcard.com.cn/",
            },
          ],
        },
        {
          label: "Partner Institution",
          children: [
            {
              label: "Zhejiang University（ZJU）",
              link: "https://www.zju.edu.cn/",
            },
            {
              label: "University of Science and Technology of China (USTC)",
              link: "https://www.ustc.edu.cn/",
            },
          ],
        },
        {
          label: "Community Partner",
          children: [
            {
              label: "Linux Foundation",
              link: "https://www.linuxfoundation.org/",
            },
            {
              label: "LF Edge AI",
              link: "https://lfedge.org/projects/infiniedge-ai/",
            },
          ],
        },
        {
          label: "Strategic Cooperation",
          children: [
            {
              label: "Zhejiang Honghao Law Firm",
              link: "https://www.zjhhlawyer.com/",
            },
            {
              label: "Shaoxing Datong Accounting Firm, Co., LTD",
              link: "http://www.dtcpa.cn/",
            },
          ],
        },
        {
          label: "Invited Media",
          children: [
            {
              label: "Hangzhou City Daily News",
              link: "",
            },
            {
              label: "Trend News App",
              link: "",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/assets/style/foot.styl";
</style>
