import { createStore } from 'vuex';

const store = createStore({
	state() {
		return {
			menuIndex: sessionStorage.getItem('menuIndex') != null ? sessionStorage.getItem('menuIndex')*1 : 0,
			lang: localStorage.getItem('OnlieLang') != null ? localStorage.getItem('OnlieLang') : 'ch',
		}
	},
	mutations: {
		saveMenuIndex(state, data) {
			state.menuIndex = data;
		},
		saveLang(state, data) {
			state.lang = data;
		},
	},
	actions: {
		saveMenuIndex(context, data) {
			context.commit('saveMenuIndex', data);
		},
		saveLang(context, data) {
			context.commit('saveLang', data);
		},
	},
	getters: {
		menuIndex(state) {
			return state.menuIndex;
		},
		lang(state) {
			return state.lang;
		},
	},
});

export default store
