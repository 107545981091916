<template>
  <a-config-provider :locale="locale">
    <page-head
      :menuIndex="menuIndex"
      :openMenu="openMenu"
      :lang="lang"
      v-show="hideHeader"
      @toggle="toggleMenu"
      @menuClick="routerTo"
      @setLang="setLang"
    ></page-head>
    <router-view></router-view>
    <page-foot :lang="lang"></page-foot>
  </a-config-provider>
</template>

<script>
import pageHead from "@/components/head";
import pageFoot from "@/components/foot";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { getTokenFromUrl } from "@/utils";
import { nextTick } from "vue";
dayjs.locale("zh-cn");
export default {
  name: "App",
  components: {
    pageHead,
    pageFoot,
  },
  data() {
    return {
      locale: zhCN,
      menuIndex:
        sessionStorage.getItem("menuIndex") != null
          ? sessionStorage.getItem("menuIndex") * 1
          : 0,
      openMenu: false,
      lang:
        localStorage.getItem("OnlieLang") != null
          ? localStorage.getItem("OnlieLang")
          : "ch",
    };
  },
  computed: {
    hideHeader() {
      const hideList = ["ChatBotDetail", "ChatBot"];
      return !hideList.includes(this.$route.name);
    },
  },
  mounted() {
    let lang = localStorage.getItem("OnlieLang");
    document.body.setAttribute("class", lang);
    this.initRem();
    this.reDirect();

    const obj = getTokenFromUrl("token");
    if (obj.token) {
      localStorage.setItem("token", obj.token);
      console.log(window.location.href.replace(`?token=${obj.token}`, ""));
      setTimeout(() => {
        history.replaceState(
          {},
          "",
          window.location.href.replace(`?token=${obj.token}`, "")
        );
      }, 200);
      // const url = window.location.href.replace(`?token=${obj.token}`, "");
      // window.location.replace(url);
      // this.$router.push({
      //   name: this.$router.currentRoute.value.name,
      // });
    }
  },
  watch: {
    "$store.state.menuIndex": function (newVal) {
      this.menuIndex = newVal * 1;
    },
    "$store.state.lang": function (newVal) {
      // watch 无法获取组件改变后的新值
      this.lang = newVal;
    },
  },
  methods: {
    initRem() {
      let winW = document.body.clientWidth;
      // console.log(winW)
      if (winW <= 990) {
        document.documentElement.style.fontSize =
          document.documentElement.clientWidth / 7.5 + "px";
      } else {
        document.documentElement.style.fontSize = 100 + "px";
      }
      window.onresize = () => {
        this.initRem();
      };
    },
    reDirect() {
      let lang = this.lang;

      let oldpath = this.$route.fullPath;
      if (lang == "en" && oldpath.indexOf("/en") == -1) {
        this.routerTo("/en" + oldpath);
      }
      if (lang == "ch" && oldpath.indexOf("/en") !== -1) {
        this.routerTo(oldpath.replace("/en", ""));
      }
    },
    toggleMenu() {
      this.openMenu = !this.openMenu;
    },
    routerTo(path) {
      if (path == "") {
        path = "/";
      }
      this.$router.push({
        path: path,
      });
      this.openMenu = false;
    },
    setLang(lang) {
      document.body.setAttribute("class", lang);
      localStorage.setItem("OnlieLang", lang);
      this.$store.commit("saveLang", lang);

      let oldpath = this.$route.fullPath;
      console.log(lang, oldpath);
      if (lang == "en" && oldpath.indexOf("/en") == -1) {
        this.routerTo("/en" + oldpath);
      }
      if (lang == "ch" && oldpath.indexOf("/en") !== -1) {
        this.routerTo(oldpath.replace("/en", ""));
      }
    },
  },
};
</script>
